import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import {
  Address,
  BankAccountType,
  Entity,
  PermissionLevels,
  validatePhone,
} from '@debbie/common/dist'
import { getCollectorOnCase } from '@debbie/common/dist/cases'
import helpers from '@debbie/common/dist/economy/helpers'
import { InteractionType, Direction } from '@debbie/common/dist/interactions'
import { Lang } from '@debbie/common/dist/lang'
import { c, h } from '@debbie/cortex/dist'
import * as customersAPI from '@debbie/cortex/dist/api/customers'
import { useCaseVouchersByCase } from '@debbie/cortex/dist/case-vouchers'
import {
  selectCaseById,
  selectCaseError,
} from '@debbie/cortex/dist/cases/selectors'
import {
  pendingCustomerOverpaymentsKey,
  useCustomer,
  usePendingCustomerOverpayments,
} from '@debbie/cortex/dist/customers'
import { useDocumentsByReference } from '@debbie/cortex/dist/documents'
import { selectMe, selectRole } from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  joinCaseWatchRoom,
  leaveCaseWatchRoom,
} from '@debbie/cortex/dist/socket'
import {
  Content,
  PageBase,
  Panel,
  Button,
  Money,
  PlaceholderLoader,
  Pay,
} from '@debbie/iris/dist/components'
import BankAccount from '@debbie/iris/dist/components/economy/BankAccount'
import CaseVouchers from '@debbie/iris/dist/components/economy/case-vouchers/CaseVouchers'
import { Row, Col, Container } from '@debbie/iris/dist/components/Grid'
import CaseHistory from '@debbie/iris/dist/components/Hist/CaseHistory'
import Mail from '@debbie/iris/dist/components/Icons/Mail'
import Phone from '@debbie/iris/dist/components/Icons/Phone'
import ThumbsUp from '@debbie/iris/dist/components/Icons/ThumbsUp'
import LanguageInput from '@debbie/iris/dist/components/LanguageInput'
import THEME, { updateTheme } from '@debbie/iris/dist/theme'

import Documentation from '../../components/Documentation'
import { APIBASE } from '../../config'

import {
  AccountInput,
  Footer,
  Info,
  Header,
  OnlyMobile,
  OnlyNotMobile,
  Intro,
} from './CaseStyles'
import q from '@debbie/cortex/dist/query'
import DebtorInfo from '../../components/DebtorInfo'
import { selectTenant } from '@debbie/cortex/dist/tenant/selectors'
import { useCreditor } from '@debbie/cortex/dist/creditors'
import { useCollector } from '@debbie/cortex/dist/collectors'
import { NoteInteraction } from '@debbie/iris/dist/components/interactions'
import { VoucherCategory } from '@debbie/common/dist/economy/vouchers'

interface Props {
  paid?: boolean
}

interface ProfileData {
  status: 'not-loaded' | 'loading' | 'loaded'
  name: string | null
  email: string | null
  phone: string | null
  address: Address | null
}

const initialProfile = {
  name: null,
  email: null,
  phone: null,
  address: {
    address: null,
    zipcode: null,
    city: null,
    country: null,
  },
}

const Case = (props: Props) => {
  const params = useParams<{ id: string }>()
  const id = params.id

  const singleCase = useSelector((s: RootState) => selectCaseById(s, id))
  const caseError = useSelector((s: RootState) => selectCaseError(s, id))
  const lang = useSelector((s: RootState) => s.lang)
  const currentLang = useSelector((s: RootState) => s.currentLang)
  const me = useSelector(selectMe)
  const meRole = useSelector(selectRole)

  const caseVouchers_ = useCaseVouchersByCase(singleCase?.caseId)
  const caseVouchers = h.hasData(caseVouchers_) ? caseVouchers_.data : null

  const tenant = useSelector(selectTenant)
  const creditorLoadable = useCreditor(singleCase?.creditorId)
  const creditor = h.hasData(creditorLoadable) ? creditorLoadable.data : null
  const customerLoader = useCustomer(singleCase?.customerId)
  const customer = h.hasData(customerLoader) ? customerLoader.data : null
  const pendingOverpayments = usePendingCustomerOverpayments(
    customer?.customerId,
  )

  const collectorLoadable = useCollector(
    (singleCase &&
      creditor &&
      getCollectorOnCase(
        singleCase.type,
        singleCase.collectorId,
        creditor.identityCollectorId,
        creditor.defaultCollectorId,
      )) ||
      undefined,
  )

  const collector = h.hasData(collectorLoadable) ? collectorLoadable.data : null

  const [previousId, setPreviousId] = useState<null | number | string>(null)
  const [editingBankAccount, setEditingBankAccount] = useState<boolean>(false)
  const [accountNumber, setAccountNumber] = useState<[string, string]>(['', ''])
  const [profileData, setProfileData] = useState<ProfileData>({
    ...initialProfile,
    status: 'not-loaded',
  })

  useEffect(() => {
    if (previousId) {
      leaveCaseWatchRoom(`${previousId}`)
      setPreviousId(id)
    }

    if (!singleCase) {
      c.cases.getById(id, true)
    }

    const timestamp = Date.now()
    joinCaseWatchRoom(`${id}`, timestamp)

    return () => leaveCaseWatchRoom(`${id}`)
  }, [id])

  useEffect(() => {
    if (singleCase && customer) {
      getProfileData()
    }
  }, [singleCase, customer])

  useEffect(() => {
    if (singleCase) {
      c.newInteractions.createNewInteraction(singleCase.caseId)
    }
  }, [singleCase])

  const documentsLoadable = useDocumentsByReference(singleCase?.caseId)
  const documents = h.hasData(documentsLoadable) ? documentsLoadable.data : null

  useEffect(() => {
    if (collector) {
      updateTheme(collector.theme)
    }
  }, [collector])

  const getProfileData = () => {
    const debtorUser =
      me && customer && customer.users.find((user) => user.userId === me.id)

    if (debtorUser) {
      setProfileData({
        status: 'loaded',
        email: debtorUser.details.email,
        name: debtorUser.details.name,
        phone: debtorUser.details.phone && debtorUser.details.phone.number,
        address: debtorUser.details.address,
      })
    } else if (profileData.status !== 'loading') {
      setProfileData({
        ...profileData,
        status: 'loading',
      })
    }
  }

  const onSubmit = (e, verify: boolean = false) => {
    e.stopPropagation()
    e.preventDefault()

    const changed =
      customer.bankAccount?.accountNumber !== accountNumber[1] ||
      customer.bankAccount?.registrationNumber !== accountNumber[0]

    customersAPI
      .updateBankAccount(
        singleCase.customerId,
        verify
          ? undefined
          : changed
            ? {
                type: BankAccountType.Danish,
                registrationNumber: accountNumber[0],
                accountNumber: accountNumber[1],
              }
            : undefined,
      )
      .then(() => {
        setEditingBankAccount(false)
        c.customers
          .getById(singleCase.customerId)
          .then(() => setAccountNumber(['', '']))

        q.invalidate([pendingCustomerOverpaymentsKey(singleCase.customerId)])
      })
  }

  if (!singleCase) {
    return (
      <Content fullHeight>
        <Container>
          <Row>
            <Col size="md" width="5" style={{ paddingTop: '80px' }}>
              <PlaceholderLoader style={{ marginTop: '30px' }} />
              <PlaceholderLoader width="40%" style={{ marginTop: '30px' }} />
              <PlaceholderLoader width="60%" style={{ marginTop: '30px' }} />
            </Col>
          </Row>
        </Container>
      </Content>
    )
  }

  if (caseError) {
    return (
      <PageBase>
        <Panel>{caseError}</Panel>
      </PageBase>
    )
  }

  if (singleCase && singleCase.deleted) {
    return (
      <PageBase>
        <Panel>{lang.cases.caseHasBeenDeleted}</Panel>
      </PageBase>
    )
  }

  const paid =
    props.paid ||
    (caseVouchers && -helpers.overview.balance(caseVouchers) < 500)

  const hasPayment =
    caseVouchers &&
    caseVouchers.find(
      (cv) => cv.category === VoucherCategory.TRANSACTION && cv.amount > 0,
    )

  const overpayment = caseVouchers && helpers.overview.balance(caseVouchers) > 0

  const bankAccountExists = customer?.bankAccount != null

  const bankAccountNeedsVerification =
    h.hasData(pendingOverpayments) && pendingOverpayments.data.length > 0

  return (
    <div>
      <Content fullHeight>
        <Container>
          <Row>
            <Col size="md" width="12">
              {paid && (
                <Panel style={{ background: 'white', marginTop: '50px' }}>
                  <h1 style={{ textAlign: 'center' }}>
                    {hasPayment
                      ? lang.debtorWeb.congratsDeposit
                      : lang.debtorWeb.congratsCaseEnd}{' '}
                    <ThumbsUp />
                  </h1>
                  <p style={{ textAlign: 'center' }}>
                    {lang.debtorWeb.satisfactionOne}{' '}
                    {
                      // @ts-ignore
                      <Link to={`/cases/${singleCase.id}/satisfaction`}>
                        {lang.debtorWeb.satisfactionTwo}
                      </Link>
                    }{' '}
                    {lang.debtorWeb.satisfactionThree}
                  </p>
                </Panel>
              )}
            </Col>
          </Row>
          <Row>
            {overpayment && (
              <Col size="md" width="12">
                <Panel style={{ textAlign: 'center' }}>
                  <h1>{lang.debtorWeb.overpaymentHeader}</h1>
                  <p>
                    {lang.debtorWeb.overpaymentPreAmount} {singleCase.currency}{' '}
                    <Money amount={helpers.overview.balance(caseVouchers)} />{' '}
                    {lang.debtorWeb.overpaymentPostAmount}
                  </p>
                  {bankAccountExists && !editingBankAccount ? (
                    <>
                      <BankAccount
                        editable={true}
                        onEdit={() => setEditingBankAccount(true)}
                        accountDetails={customer.bankAccount}
                      />
                      {bankAccountNeedsVerification && (
                        <>
                          <br />
                          <Button
                            onClick={(e) => {
                              onSubmit(e, true)
                            }}
                          >
                            {lang.misc.submit}
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <form
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onSubmit={onSubmit}
                      >
                        <AccountInput
                          onChange={(accountNumber) =>
                            setAccountNumber(accountNumber)
                          }
                          value={accountNumber}
                        />
                        <Button
                          style={{ marginBottom: '20px', padding: '13px' }}
                        >
                          {lang.misc.submit}
                        </Button>
                      </form>
                      {editingBankAccount && (
                        <Button
                          theme="secondary"
                          size="small"
                          onClick={() => setEditingBankAccount(false)}
                        >
                          {lang.misc.cancel}
                        </Button>
                      )}
                    </>
                  )}
                </Panel>
              </Col>
            )}
          </Row>
          <Row>
            <Col size="md" width="6">
              {singleCase && (
                <Intro>
                  {profileData && (
                    <h1 style={{ marginTop: '80px' }}>
                      {lang.debtorWebHome.dear} <span>{profileData.name}</span>
                    </h1>
                  )}
                  {meRole && meRole === PermissionLevels.alternativeContact && (
                    <p>{lang.debtorWebHome.representative}</p>
                  )}
                  <p>
                    {meRole && meRole === PermissionLevels.alternativeContact
                      ? lang.debtorWebHome.debtor
                      : lang.debtorWebHome.you}{' '}
                    {lang.debtorWebHome.owe}{' '}
                    <b style={{ color: 'black' }}>
                      {singleCase && singleCase.currency}{' '}
                      {caseVouchers && (
                        <Money
                          amount={-helpers.overview.balance(caseVouchers)}
                        />
                      )}
                    </b>{' '}
                    {lang.debtorWebHome.to}{' '}
                    <b style={{ color: 'black' }}>
                      {creditor && creditor.name}
                    </b>
                    . {lang.debtorWebHome.youCanDoThisHere}
                  </p>
                </Intro>
              )}
              <OnlyMobile style={{ margin: `${THEME.GRID_SPACING}px 0` }}>
                {collector && (
                  <Pay
                    case={singleCase}
                    collector={collector}
                    isPaymentPlan={false}
                  />
                )}
              </OnlyMobile>
              {caseVouchers && (
                <CaseVouchers
                  hideExpiredCaseVouchers
                  caseVouchers={caseVouchers.map((cv) => {
                    return {
                      ...cv,
                      children: [],
                    }
                  })}
                  singleCase={singleCase}
                />
              )}
              <Header>{lang.debtorWebHome.yourInfo}</Header>
              <DebtorInfo caseId={singleCase.caseId} />
              {creditor && creditor.debtorFAQ && (
                <>
                  <Header>{lang.debtorWebHome.faq}</Header>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: creditor.debtorFAQ,
                    }}
                  ></div>
                </>
              )}
              {documents && !!documents.length && (
                <>
                  <Header>{lang.debtorWeb.documentation}</Header>
                  <div
                    style={{
                      overflowX: 'scroll',
                      overflowY: 'hidden',
                      whiteSpace: 'nowrap',
                      display: 'flex',
                    }}
                  >
                    {documents.map((document) => (
                      <Documentation key={document.id} document={document} />
                    ))}
                  </div>
                </>
              )}
              <Header>{lang.debtorWebHome.contactUs}</Header>
              {collector && (
                <Row>
                  <Col size="md" width="8">
                    <p>
                      {tenant?.settings?.debtorweb?.contactUsText ||
                        lang.debtorWebHome.contactUsText}
                    </p>
                    <Info>
                      {collector.email && (
                        <>
                          <Mail /> {collector.email}
                        </>
                      )}
                      {validatePhone(collector.phone) && (
                        <>
                          <Phone
                            style={{
                              marginLeft: collector.email
                                ? `${THEME.GRID_SPACING}px`
                                : '0px',
                            }}
                          />{' '}
                          +{collector.phone.locale} {collector.phone.number}
                        </>
                      )}
                    </Info>
                  </Col>
                  <Col size="md" width="4">
                    {collector.logo ? (
                      <img
                        width="100%"
                        alt={collector.logo.fileName}
                        src={`${APIBASE}/files/${collector.logo.fileId}/${collector.logo.fileName}`}
                      />
                    ) : (
                      <span>{collector.name}</span>
                    )}
                  </Col>
                </Row>
              )}
              <br />
              <br />
              {singleCase && (
                <NoteInteraction
                  referenceId={singleCase.caseId}
                  referenceType={Entity.Case}
                  id={singleCase.id}
                  options={{
                    direction: Direction.INCOMING,
                    type: InteractionType.DebtorNote,
                  }}
                  sendButtonText={lang.debtorWebHome.send}
                  textAreaPlaceholder={
                    profileData.email
                      ? lang.debtorWebHome.yourMessage
                      : lang.debtorWebHome.enterEmail
                  }
                  textAreaDisabled={!profileData.email}
                />
              )}
              {id && <CaseHistory caseId={`${id}`} />}
            </Col>
            <Col size="md" width="1" />
            <Col
              size="md"
              width="5"
              style={{
                position: 'sticky',
                top: `${THEME.GRID_SPACING}px`,
                marginTop: '50px',
              }}
            >
              <OnlyNotMobile>
                <Pay
                  case={singleCase}
                  collector={collector}
                  isPaymentPlan={false}
                />
              </OnlyNotMobile>
            </Col>
          </Row>
        </Container>
        <Footer>
          {collector && (
            <ul>
              <li>
                <a onClick={() => c.auth.logout()}>
                  {lang.debtorWebHome.logout}
                </a>
              </li>
              {collector.privacyPolicy && (
                <li>
                  <a target="_blanc" href={collector.privacyPolicy}>
                    {lang.debtorWebHome.privacyPolicy}
                  </a>
                </li>
              )}
              {collector.businessTerms && (
                <li>
                  <a target="_blanc" href={collector.businessTerms}>
                    {lang.debtorWebHome.businessTerms}
                  </a>
                </li>
              )}
              <li>{collector.name}</li>
              {collector.centralRegisterId && (
                <li>CVR: {collector.centralRegisterId}</li>
              )}
              {collector.address &&
                collector.address.address &&
                collector.address.zipcode &&
                collector.address.city && (
                  <li>
                    {collector.address.address}, {collector.address.zipcode}{' '}
                    {collector.address.city}
                  </li>
                )}
            </ul>
          )}
          <div
            style={{
              textAlign: 'center',
              marginTop: '2em',
            }}
          >
            <LanguageInput
              language={currentLang}
              onChange={(lang) => c.lang.setLang(lang)}
              availableLanguages={[Lang.da, Lang.en]}
            />
          </div>
          {collector && collector.logo && (
            <img
              alt={collector.logo.fileName}
              src={`${APIBASE}/files/${collector.logo.fileId}/${collector.logo.fileName}`}
            />
          )}
        </Footer>
      </Content>
    </div>
  )
}

export default Case
