import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { h } from '@debbie/cortex/dist'
import { useCaseList } from '@debbie/cortex/dist/cases'

import {
  PageBase,
  Content,
  Panel,
  Table,
  Loading,
} from '@debbie/iris/dist/components'
import { Col, Row } from '@debbie/iris/dist/components/Grid'

import { CaseSummary } from '.'

const Cases = () => {
  const history = useHistory()

  const caseListLoadable = useCaseList({}, 0, 100)
  const caseList = h.hasData(caseListLoadable) ? caseListLoadable.data : null

  useEffect(() => {
    if (caseList && caseList.items.length === 1) {
      const path = `/cases/${caseList[0].id}`
      history.push(path)
    }
  }, [caseList])

  return (
    <div>
      <Content>
        <PageBase>
          <Row>
            <Col size="md" width="12">
              <Panel header="Alle sager">
                {caseList ? (
                  caseList.items.length > 0 ? (
                    <Table>
                      <tr>
                        <th>ID</th>
                        <th style={{ width: '120px' }} />
                      </tr>
                      {caseList.items.map((c) => (
                        <CaseSummary case={c} key={c.id} />
                      ))}
                    </Table>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <span
                        style={{
                          fontStyle: 'italic',
                          opacity: '0.8',
                          fontSize: '1em',
                        }}
                      >
                        No cases found
                      </span>
                    </div>
                  )
                ) : (
                  <Loading width="30px" />
                )}
              </Panel>
            </Col>
          </Row>
        </PageBase>
      </Content>
    </div>
  )
}

export default Cases
