import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { PermissionLevels } from '@debbie/common/dist'
import { Case, getCollectorOnCase } from '@debbie/common/dist/cases'
import { Plan } from '@debbie/common/dist/economy/payment-plans'
import { UserRole } from '@debbie/common/dist/users'
import * as paymentPlansAPI from '@debbie/cortex/dist/api/payment-plans'
import { RootState } from '@debbie/cortex/dist/reducer'
import { Button, Panel } from '@debbie/iris/dist/components'
import { useCreditor } from '@debbie/cortex/dist/creditors'
import { h } from '@debbie/cortex/dist'
import { useCollector } from '@debbie/cortex/dist/collectors'

interface State {
  loading: boolean
}

interface Props {
  meRole: UserRole
  singleCase: Case
  paymentPlan: Plan
}

const SigningStep = (props: Props) => {
  const { meRole, singleCase, paymentPlan } = props

  const [state, setState] = useState<State>({
    loading: false,
  })

  const creditorLoadable = useCreditor(singleCase?.creditorId)
  const creditor = h.hasData(creditorLoadable) ? creditorLoadable.data : null

  const collectorLoadable = useCollector(
    (singleCase &&
      creditor &&
      getCollectorOnCase(
        singleCase.type,
        singleCase.collectorId,
        creditor.identityCollectorId,
        creditor.defaultCollectorId,
      )) ||
      undefined,
  )
  const collector = h.hasData(collectorLoadable) ? collectorLoadable.data : null

  const lang = useSelector((s: RootState) => s.lang)

  return (
    <Panel header={lang.debtorWebPaymentPlans.signedDoc}>
      {
        <div>
          <p>
            {lang.debtorWebPaymentPlans.signTextOne}{' '}
            {collector && collector.name}{' '}
            {meRole && meRole === PermissionLevels.alternativeContact
              ? lang.debtorWebPaymentPlans.signTextTwoAlternativeContact
              : lang.debtorWebPaymentPlans.signTextTwoDebtor}
          </p>
          <Button
            onClick={async () => {
              setState({
                ...state,
                loading: true,
              })

              try {
                const link = await paymentPlansAPI.getSigningLink(
                  paymentPlan.paymentPlanId,
                  window.location.href,
                  window.location.href,
                )

                location.replace(link.url)
              } catch (error) {
                console.error(error)
                alert('Error. Please try again later.')
              }

              setState({
                ...state,
                loading: false,
              })
            }}
            loading={state.loading}
            className="ui-test-d31d1806"
          >
            {lang.debtorWebPaymentPlans.agreement}
          </Button>
        </div>
      }
    </Panel>
  )
}

export default SigningStep
