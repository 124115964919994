import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import * as paymentPlanHelpers from '@debbie/common/dist/economy/payment-plans'
import { Currency, formatNumber } from '@debbie/common/dist'
import { CaseVoucher } from '@debbie/common/dist/economy/case-vouchers'
import { suggestedPlans as mapVoucherToPlans } from '@debbie/common/dist/economy/helpers/payment-plans'
import { c, h } from '@debbie/cortex/dist'
import { paymentPlans as paymentPlansApi } from '@debbie/cortex/dist/api'
import { selectCaseById } from '@debbie/cortex/dist/cases/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import { selectTenant } from '@debbie/cortex/dist/tenant/selectors'
import {
  Button,
  Panel,
  PlaceholderLoader,
  Money,
} from '@debbie/iris/dist/components'
import ArrowRight from '@debbie/iris/dist/components/Icons/ArrowRight'

import { DARK_COLOR_SECONDARY, GRID_SPACING, DATE_FORMAT_SHORT } from '../theme'
import { getExpirationSettings } from '@debbie/common/dist/economy/helpers/interest'
import q from '@debbie/cortex/dist/query'
import { paymentPlansByCaseIdKey } from '@debbie/cortex/dist/paymentPlans'
import { useDocumentsByReference } from '@debbie/cortex/dist/documents'
import { useCreditor } from '@debbie/cortex/dist/creditors'

interface Props {
  caseId: string
  caseVouchers: CaseVoucher[]
  currency: Currency
}

const SelectPlanStep = (props: Props) => {
  const params = useParams<{ id: string }>()
  const { id } = params
  const { caseId, caseVouchers, currency } = props

  const lang = useSelector((s: RootState) => s.lang)
  const isAuthTokenLoaded = useSelector((s: RootState) =>
    h.isLoaded(s.authToken),
  )
  const singleCase = useSelector((s: RootState) => selectCaseById(s, id))
  const tenant = useSelector(selectTenant)
  const creditorLoadable = useCreditor(singleCase?.creditorId)
  const creditor = h.hasData(creditorLoadable) ? creditorLoadable.data : null
  const documentsLoader = useDocumentsByReference(singleCase?.caseId)
  const documents = h.hasData(documentsLoader) ? documentsLoader.data : null

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadedSuggestedPlans, setLoadedSuggestedPlans] =
    useState<boolean>(false)
  const [suggestedPlans, setSuggestedPlans] = useState<any[]>([])

  const selectPlan = async (planId: string) => {
    setIsLoading(true)

    if (isAuthTokenLoaded) {
      await paymentPlansApi.create(caseId, {
        selectedPaymentPlanId: planId,
        activationTrigger: true,
        config: null,
      })

      q.invalidate([paymentPlansByCaseIdKey(caseId)])

      await c.cases.getById(id)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (!tenant && singleCase) {
      c.tenants.get(singleCase.tenantId)
    }
  }, [singleCase])

  useEffect(() => {
    if (
      caseVouchers &&
      caseVouchers.length > 0 &&
      documents &&
      tenant &&
      creditor
    ) {
      setLoadedSuggestedPlans(true)
      setSuggestedPlans(
        mapVoucherToPlans(
          caseVouchers,
          documents,
          getExpirationSettings(tenant, creditor),
          tenant,
          creditor,
        ),
      )
    }
  }, [caseVouchers, tenant, creditor, documents])

  const paymentPlanConfig =
    creditor?.paymentPlansConfig ||
    tenant?.paymentPlansConfig ||
    paymentPlanHelpers.defaultPaymentPlansConfig

  return (
    <Panel header={lang.debtorWebPaymentPlans.selectPlan}>
      {isLoading ? (
        <PlaceholderLoader />
      ) : suggestedPlans ? (
        (suggestedPlans.length === 0 && loadedSuggestedPlans) ||
        paymentPlanConfig.disablePaymentPlans ? (
          <h2>{lang.paymentPlans.cannotOfferPaymentPlan}</h2>
        ) : (
          suggestedPlans.map((plan) => (
            <StyledPlan
              key={plan.id}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <div className="price">
                {plan && (
                  <span>
                    <Money amount={plan.monthlyAmount} />
                    <div>
                      {currency} / {lang.debtorWebPaymentPlans.month}
                    </div>
                  </span>
                )}
              </div>
              <h2>
                {plan.months}{' '}
                <span style={{ fontWeight: 'normal' }}>
                  {lang.debtorWebPaymentPlans.months}
                </span>
              </h2>
              <p>
                {moment(plan.from).format(DATE_FORMAT_SHORT)} <ArrowRight />{' '}
                {moment(plan.to).format(DATE_FORMAT_SHORT)}
              </p>
              <p>
                {lang.debtorWebPaymentPlans.total}{' '}
                <b>
                  {currency} {formatNumber(plan.total)}
                </b>
              </p>
              <Button
                onClick={() => selectPlan(plan.id)}
                loading={isLoading}
                theme="secondary"
              >
                {lang.debtorWebPaymentPlans.select}
              </Button>
            </StyledPlan>
          ))
        )
      ) : (
        <div>{lang.debtorWebPaymentPlans.noOptions}</div>
      )}
    </Panel>
  )
}

export default SelectPlanStep

const StyledPlan = styled.div<{ active?: boolean }>`
  border-bottom: 1px solid lightgrey;
  user-select: none;
  transition: padding 0.3s;
  background-color: ${(props) => (props.active ? 'lightgrey' : 'transparent')};
  padding: ${(props) =>
    props.active
      ? `${GRID_SPACING}px ${GRID_SPACING}px`
      : `${GRID_SPACING}px 0`};
  cursor: pointer;

  &:hover {
    padding: ${GRID_SPACING}px ${GRID_SPACING}px;
    background-color: ${(props) => (props.active ? 'lightgrey' : '#efefef')};
  }

  &:last-child {
    border-bottom: none;
  }

  & > h2 {
    margin: 0 !important;
  }

  p {
    font-weight: bold;
    color: ${DARK_COLOR_SECONDARY};
    margin-top: 8px;
  }

  p:last-child {
    font-weight: initial;
    color: inherit;
    margin-bottom: 0px;
  }

  div.price {
    font-size: 3em;
    float: right;
    text-align: right;

    & > span > div {
      font-size: 0.5em;
    }
  }
`
