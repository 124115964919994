import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Case } from '@debbie/common/dist/cases'
import * as paymentPlanHelpers from '@debbie/common/dist/economy/payment-plans'

import { Panel } from '@debbie/iris/dist/components'

import { c, h } from '@debbie/cortex/dist'
import { RootState } from '@debbie/cortex/dist/reducer'
import { selectTenant } from '@debbie/cortex/dist/tenant/selectors'

import DebtorInfo from './DebtorInfo'
import { useCreditor } from '@debbie/cortex/dist/creditors'

interface Props {
  singleCase: Case
  onSkip: () => void
}

const GatherInfoStep = (props: Props) => {
  const { singleCase } = props

  const lang = useSelector((s: RootState) => s.lang)
  const tenant = useSelector(selectTenant)
  const creditorLoadable = useCreditor(singleCase?.creditorId)
  const creditor = h.hasData(creditorLoadable) ? creditorLoadable.data : null

  useEffect(() => {
    if (!tenant && singleCase) {
      c.tenants.get(singleCase.tenantId)
    }
  }, [singleCase])

  const paymentPlanConfig =
    creditor?.paymentPlansConfig ||
    tenant?.paymentPlansConfig ||
    paymentPlanHelpers.defaultPaymentPlansConfig

  return (
    <Panel header={lang.debtorWebPaymentPlans.gatherInfo}>
      {
        <div>
          <p>{lang.debtorWebPaymentPlans.signTextThree}</p>
          <DebtorInfo
            caseId={singleCase.caseId}
            required
            skippable
            onSkip={props.onSkip}
            requireCentralRegisterId={
              paymentPlanConfig.requireCentralRegisterId
            }
          />
        </div>
      }
    </Panel>
  )
}

export default GatherInfoStep
